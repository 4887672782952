import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { StepsContext } from "../Context/Steps.Context";
import Blue from '../../Assets/blue.png'
import Mustaqeem from '../../Assets/mustaqeem.png'

import { useHistory } from "react-router";
import Animatedpage from "../animatepage";
import axios from "axios";


const AccountType = () => {
    const { data, setData } = useContext(DataContext);
    const { setActive } = useContext(StepsContext);
    const history = useHistory();
    useEffect(() => {
        setActive("Banking Segment");
        fetch("api/DigitalAccountApi/GetBankingType").then((res) => res.json().then((resp) => {

            setAccounttypelist([...resp]);
            setData({ ...data, selectedAT: resp[0].BankingType })
        })).catch(err => {
            
        })

    }, [])
    const [Accounttypelist, setAccounttypelist] = useState([]);

    return (
        <Animatedpage useauth={true}>
            <Grid item xs={12} style={{ marginBottom: 50 }}>
                <Row className="slide">
                    {/* <p style={{ marginTop: 30 }} className="Qhelper" id="s1q1">Question <b>1</b></p> */}
                    <h1 className="Q" style={{ fontSize: 20, marginTop: 20 }}>Select your prefered banking segment</h1>
                    {Accounttypelist.map(p => (
                        <div key={p.id} className={"AccountTypediv" + (data.selectedAT === p.BankingType ? " Active" : "")} onClick={() => {
                            setData({ ...data, selectedAT: p.BankingType })
                            
                        }}>
                            {p.BankingType === "" ? <img src={Mustaqeem} style={{ width: 100 }} /> : <img src={Blue} style={{ width: 160 }} />}
                            {data.selectedAT === p.BankingType ? <CheckBox className="AccountTypeCheck" style={{ color: "var(--light)" }} /> : null}
                            <p className="AccountTypeName" style={{ fontWeight: "bold" }}>{p.BankingType}</p>
                            <div>
                                <ul>
                                    {p.Description.map(i => (
                                        <li key={i}>{i}</li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    ))}
                    <br />
                    <Button variant="contained" onClick={() => {

                        history.push("accountoption")
                    }} style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} >Next
                        <ArrowRight />
                    </Button>
                </Row>
            </Grid>
        </Animatedpage>
    )
}

export default AccountType;